import React from "react";
//import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const Services = () => {
  return (
    <React.Fragment>
      <section className="section" id="services">
        <Container>
          <Row className="justify-content-center">
            <Col lg={9}>
              <div className="heading-box text-center">
                <h3 className="heading-title fw-normal">
                  Our many years of experience make us experts at
                  <br />
                  <span className="fw-bold">Web Services</span> that promote new
                  business opportunites.
                </h3>
                {/*<p className="heading-desc text-muted mt-4">
                  We craft digital, graphic and dimensional thinking, to create
                  category leading brand experiences that have meaning and add a
                  value for our clients.
  </p>*/}
              </div>
            </Col>
          </Row>

          <Row className="">
            {" "}
            {/*mt-5 pt-4*/}
            <Col lg={4}>
              <div className="services-box text-center hover-effect mt-4">
                <div className="services-icon">
                  <i className="pe-7s-paint"></i>
                </div>
                <h5 className="mt-4 pt-1">Creative Design</h5>
                <p className="text-muted mt-3 mb-0">
                  From design to implementation, we provide the creative juice
                  to make your business stand out.
                </p>
                {/* <div className="mt-4">
                  <Link to="#" className="text-primary">
                    Read More <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div> */}
              </div>
            </Col>
            <Col lg={4}>
              <div className="services-box text-center hover-effect mt-4">
                <div className="services-icon">
                  <i className="pe-7s-phone"></i>
                </div>
                <h5 className="mt-4 pt-1">Digital Solutions</h5>
                <p className="text-muted mt-3 mb-0">
                  From simple websites to powerful web applications, we offer a
                  full range of services to suit your business.
                </p>
                {/* <div className="mt-4">
                  <Link to="#" className="text-primary">
                    Read More <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div> */}
              </div>
            </Col>
            <Col lg={4}>
              <div className="services-box text-center hover-effect mt-4">
                <div className="services-icon">
                  <i className="pe-7s-add-user"></i>
                </div>
                <h5 className="mt-4 pt-1">Awesome Support</h5>
                <p className="text-muted mt-3 mb-0">
                  We offer human support 7 days a week to ensure your web
                  presence grows as your business grows.
                </p>
                {/* <div className="mt-4">
                  <Link to="#" className="text-primary">
                    Read More <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Services;
