import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Modal, Row } from "reactstrap";

const Section = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return (
    <React.Fragment>
      <section className="bg-home-2 align-items-center d-flex" id="home">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12}>
              <div className="text-center">
                <h1 className="display-5 fw-normal">
                  Create Amazing Landing Page With{" "}
                  <span className="fw-bold">Zoise</span>
                </h1>

                <p className="home-desc text-muted mt-4">
                  A digital web design studio creating modern & engaging online.
                  Separated they live in Bookmarksgrove right the Semantics, a
                  large language ocean.
                </p>

                <div className="mt-4">
                  <Link to="#" className="btn btn-primary btn-round me-3 mt-4">
                    Get Started
                  </Link>
                  <Link to="#" className="btn btn-blue btn-round mt-4">
                    Learn More
                  </Link>
                </div>
                <div className="pt-2 mt-5">
                  <Link
                    to="#"
                    onClick={toggle}
                    className="video-icon-btn bg-white"
                  >
                    <span className="play-shadow">
                      <span className="home-btn">
                        <i className="mdi mdi-play text-center"></i>
                      </span>
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={modal}
          toggle={toggle}
          className="bd-example-modal-lg"
          centered
          size="lg"
        >
          <button type="button" onClick={() => setModal(false)} className="close text-white"><span aria-hidden="true">×</span></button>
          <video id="VisaChipCardVideo" className="video-box" controls>
            <source
              src="https://www.w3schools.com/html/mov_bbb.mp4"
              type="video/mp4"
            />
          </video>
        </Modal>
      </section>
    </React.Fragment>
  );
};

export default Section;
