import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Modal, Row } from "reactstrap";

const Section = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return (
    <React.Fragment>
      <section className="bg-home-3 align-items-center d-flex" id="home">
        <div className="overlay-gradient"></div>
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <div className="text-center text-white">
                <h1 className="display-5 fw-normal">
                  We help <span className="fw-bold">startups launch</span> their
                  products
                </h1>
                <p className="home-desc mt-4 f-17">
                  A digital web design studio creating modern & engaging online.
                  Separated they live in Bookmarksgrove right the Semantics, a
                  large language ocean.
                </p>
                <div className="home-border mt-5"></div>
                <div className="pt-2 mt-5">
                  <Link
                    to="#"
                    onClick={toggle}
                    className="btn btn-danger btn-round  home-button"
                  >
                    Video Start Now
                    <span className="play-shadow ms-2">
                      <span className="home-btn">
                        <i className="mdi mdi-play text-center"></i>
                      </span>
                    </span>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={modal}
          toggle={toggle}
          className="bd-example-modal-lg"
          centered
          size="lg"
        >
           <button type="button" onClick={() => setModal(false)} className="close text-white"><span aria-hidden="true">×</span></button>
          <video id="VisaChipCardVideo" className="video-box" controls>
            <source
              src="https://www.w3schools.com/html/mov_bbb.mp4"
              type="video/mp4"
            />
          </video>
        </Modal>
      </section>
    </React.Fragment>
  );
};

export default Section;
