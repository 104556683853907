import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Modal, Row } from "reactstrap";
import Typewriter from "typewriter-effect";

//import images
import features from "../../assets/images/features/img-3.png";

const Section = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return (
    <React.Fragment>
      <section className="bg-home-5 align-items-center d-flex" id="home">
        <div className="color-overlay"></div>
        <Container>
          <Row className="justify-content-center pt-2">
            <Col lg={10}>
              <div className="text-center text-white mt-5 pt-3">
                <h1 className="display-5 mb-0">
                  <span className="typewrite">
                    <span className="wrap">
                      <Typewriter
                        options={{
                          strings: [
                            "Professional Landing Page Template",
                            "We are Creative",
                            "We are Modern",
                          ],
                          autoStart: true,
                          loop: true,
                        }}
                      />
                    </span>
                  </span>
                </h1>

                <p className="home-desc mt-4">
                  A digital web design studio creating modern & engaging online.
                  Separated they live in Bookmarksgrove right the Semantics, a
                  large language ocean.
                </p>

                <div className="mt-5">
                  <Link
                    onClick={toggle}
                    to="#"
                    className="btn btn-primary btn-round"
                  >
                    Get Started Now
                    <span className="play-shadow ms-2">
                      <span className="home-btn">
                        <i className="mdi mdi-play text-center"></i>
                      </span>
                    </span>
                  </Link>
                </div>

                <div className="home-img">
                  <img src={features} className="img-fluid" alt="" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={modal}
          toggle={toggle}
          className="bd-example-modal-lg"
          centered
          size="lg"
        >
          <button type="button" onClick={() => setModal(false)} className="close text-white"><span aria-hidden="true">×</span></button>
          <video id="VisaChipCardVideo" className="video-box" controls>
            <source
              src="https://www.w3schools.com/html/mov_bbb.mp4"
              type="video/mp4"
            />
          </video>
        </Modal>
      </section>
    </React.Fragment>
  );
};

export default Section;
