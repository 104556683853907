import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  Row,
} from "reactstrap";

const Section = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return (
    <React.Fragment>
      <section className="section bg-home-6 align-items-center" id="home">
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <div className="mt-4">
                <h1 className="home-title fw-normal">
                  Super Fast Professional Invoicing{" "}
                  <span className="fw-bold"> For Freelancers</span>
                </h1>

                <p className="home-desc text-muted mt-4">
                  A digital web design studio creating modern & engaging online.
                  Separated they live in Bookmarksgrove right the Semantics, a
                  large language ocean.
                </p>
                <div className="mt-5">
                  <Link
                    to="#"
                    onClick={toggle}
                    className="btn btn-primary btn-round"
                  >
                    Get Started Now
                    <span className="play-shadow ms-2">
                      <span className="home-btn">
                        <i className="mdi mdi-play text-center"></i>
                      </span>
                    </span>
                  </Link>
                </div>
              </div>
            </Col>

            <Col lg={5} className="offset-lg-1">
              <div className="home-registration-form bg-white p-5 mt-4">
                <h5 className="form-title mb-4 text-center">
                  Get 30 day FREE Trial
                </h5>
                <Form className="registration-form">
                  <Label className="text-muted">First Name</Label>
                  <Input
                    type="text"
                    id="exampleInputName1"
                    className="form-control mb-3 registration-input-box"
                  />
                  <Label className="text-muted">Last Name</Label>
                  <Input
                    type="text"
                    id="exampleInputName2"
                    className="form-control mb-3 registration-input-box"
                  />
                  <Label className="text-muted">Email</Label>
                  <Input
                    type="email"
                    id="exampleInputEmail1"
                    className="form-control mb-3 registration-input-box"
                  />
                  <Button className="btn btn-primary w-100 mt-3">
                    Free Trial
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={modal}
          toggle={toggle}
          className="bd-example-modal-lg"
          centered
          size="lg"
        >
          <button
            type="button"
            onClick={() => setModal(false)}
            className="close text-white"
          >
            <span aria-hidden="true">×</span>
          </button>
          <video id="VisaChipCardVideo" className="video-box" controls>
            <source
              src="https://www.w3schools.com/html/mov_bbb.mp4"
              type="video/mp4"
            />
          </video>
        </Modal>
      </section>
    </React.Fragment>
  );
};

export default Section;
