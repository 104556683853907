import React from "react";
//import Clients from "../components/Clients";
import Contact from "../components/Contact";
//import Counter from "../components/Counter";
import Cta from "../components/Cta";
//import Features from "../components/Features";
//import Pricing from "../components/Pricing";
import Services from "../components/Services";
import Footer from "./Footer";

const Layout = (props: any) => {
  console.log("Layout/index.tsx");
  return (
    <div>
      {props.children}
      <Services />
      {/*<Features />
      <Counter />
      <Clients />
      <Pricing />*/}
      <Cta />
      <Contact />
      <Footer />
    </div>
  );
};

export default Layout;
