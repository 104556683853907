import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  Row,
  Modal,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from "reactstrap";

const items = [
  {
    title: "We're Zoise, A Creative Startup Based",
  },
  {
    title: "Professional Business Page Template",
  },
  {
    title: "Experince True Business Performance",
  },
];

const Section = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item, key) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={key}
      >
        <div
          id="carouselExampleControls"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="home-center">
                <div className="home-desc-center">
                  <Container>
                    <Row className="justify-content-center">
                      <Col lg={11}>
                        <div className="text-center">
                          <h1 className="display-5">{item.title}</h1>

                          <p className="home-desc text-muted mt-4">
                            A digital web design studio creating modern &
                            engaging online. Separated they live in
                            Bookmarksgrove right the Semantics, a large language
                            ocean.
                          </p>

                          <div className="home-border mt-5"></div>

                          <div className="mt-5">
                            <Link to="#" className="btn btn-primary btn-round">
                              Get Started Now
                            </Link>
                          </div>

                          <div className="mt-5">
                            <Link to="#" onClick={toggle}>
                              <span className="play-shadow video-link">
                                <span className="home-btn text-blue f-18">
                                  <i className="mdi mdi-play-circle text-center f-20"></i>
                                  <small className="ms-2">
                                    <u>Watch intro video</u>
                                  </small>{" "}
                                </span>
                              </span>
                            </Link>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    className="bd-example-modal-lg"
                    centered
                    size="lg"
                  >
                      <button type="button" onClick={() => setModal(false)} className="close text-white"><span aria-hidden="true">×</span></button>
                    <video
                      id="VisaChipCardVideo"
                      className="video-box"
                      controls
                    >
                      <source
                        src="https://www.w3schools.com/html/mov_bbb.mp4"
                        type="video/mp4"
                      />
                    </video>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CarouselItem>
    );
  });

  return (
    <React.Fragment>
      <section className="home-slider bg-lightan" id="home">
        <div className="container-fluid">
          <div className="row">
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {slides}
              <CarouselControl
                direction="prev"
                directionText="Previous"
                onClickHandler={previous}
              />
              <CarouselControl
                direction="next"
                directionText="Next"
                onClickHandler={next}
              />
            </Carousel>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Section;
