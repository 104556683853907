import React from "react";
import NavBar from "../../Layout/Navbar";
import Section from "./Section";

const Layout1 = () => {
  console.log("Pages/Layout1/index.tsx");
  return (
    <>
      <NavBar isDark={false} />
      {/* import Section */}
      <Section />
    </>
  );
};

export default Layout1;
