import React from "react";
import routes from "./allRoutes";
import { Route, Routes } from "react-router-dom";
import Layout from "../Layout/index";

const Index = () => {
  console.log("Routes/index.tsx");
  return (
    <>
      <Routes>
        <Route>
          {routes.map((route, idx) => (
            <Route
              path={route.path}
              element={<Layout>{route.component}</Layout>}
              key={idx}
            />
          ))}
        </Route>
      </Routes>
    </>
  );
};

export default Index;
